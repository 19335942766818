<template>
  <section class="wrapper">
    <div class="tail">
      <img src="@/assets/images/image-block-14-icon.png" alt="icon" />
      <h2 class="title mb-10">
        Все это –<br />
        автоматизирован<br />ное рабочее место<br />
        разработчика
      </h2>
      <p class="description mb-10">
        Cобранные в IDE и RAD среду и реализующую мощный<br />
        потенциал базового стека технологии GTS.
      </p>
    </div>
    <img
      class="window-img"
      src="@/assets/images/image-block-14-window.png"
      alt="window"
    />
  </section>
</template>

<script>
export default {
  name: "BlockSection14",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  padding-bottom: 750px;
}

.tail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/images/image-block-14.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1033px;
  padding-bottom: 100px;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.window-img {
  position: absolute;
  bottom: -100px;
}
</style>
