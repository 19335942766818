<template>
  <section class="wrapper">
    <div class="content">
      <h3 class="sub-title outlined mb-15">главная проблема</h3>
      <h2 class="title">
        Отсутствие направлений<br />
        для развития и<br />
        инвестирования в условиях<br />
        растущей конкуренции.
      </h2>
      <div class="divider-vertical-dashed mb-15" style="height: 162px"></div>
      <div class="sub-title outlined-red mb-15">боль</div>
      <h2 class="title mb-15">
        недостаточная цифровая<br />
        инфраструктура и слабая<br />
        защита данных
      </h2>
      <h2 class="title mb-15">
        Интернет всего (IoE) -<br />
        идеальный рынок для<br />
        освоения и инвестирования,<br />
        доступный любому бизнесу
      </h2>
      <div class="tail">
        <h4 class="tail-sub-tittle mb-5">Однако,</h4>
        <h3 class="tail-tittle mb-7">
          недостаточная цифровая<br />
          инфраструктура и слабая<br />
          защита данных<br />
          ограничивают этот рост,
        </h3>
        <h4 class="tail-sub-tittle">
          требуя срочных решений для снижения рисков<br />
          и раскрытия потенциала IoE.
        </h4>
      </div>
      <div class="graphic mt-15">
        <img src="@/assets/images/image-graphic-block-17.png" alt="Graphic" />
        <h4 class="graphic-tittle">Рост рынка IoE до 2030 года</h4>
        <a href="#" class="graphic-link"
          >Источник: <span class="underline">statista.com</span></a
        >
      </div>
      <div class="divider-vertical-dashed mb-15" style="height: 162px"></div>
      <div class="sub-title outlined-green">решение</div>
      <h2 class="title my-10">
        Новые возможности для<br />
        IoE и стратегические<br />
        инвестиции
      </h2>
      <h4 class="sub-title">
        Технология GTS открывает новые горизонты для IoE,<br />
        обеспечивая стабильность и безопасность<br />
        подключенных устройств, решая проблемы надежности<br />
        софта и предлагая масштабируемые решения.
      </h4>
      <div class="tail-green">
        <h4 class="sub-title-tile">Участие в консорциуме ОНТОСЕТЬ это</h4>
        <h3 class="title-tile">
          стратегический шаг и<br />
          уникальная возможность<br />
          для первопроходцев.
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection17",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.content {
  max-width: 70%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))
      padding-box,
    linear-gradient(180deg, #ffffff33, #00000033) border-box;

  border: 2px solid transparent;
  border-radius: 146px 146px 0 0;
}

.sub-title {
  display: inline-block;
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: center;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tail {
  padding: 35px;
  border-radius: 30px;
  background: linear-gradient(180deg, #320c05, rgba(0, 0, 0, 1)) padding-box,
    linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 1))
      border-box;

  border: 2px solid transparent;
}

.tail-tittle {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.graphic {
  position: relative;
}

.graphic-tittle {
  position: absolute;
  top: 30%;
  left: 30px;
  font-size: 19px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3329px;
  text-align: left;
}

.graphic-link {
  position: absolute;
  bottom: 0;
  right: 40px;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.3329px;
  text-align: right;
  opacity: 0.5;
  text-transform: none;
}

.tail-green {
  margin-top: 100px;
  padding: 35px;
  border-radius: 30px;
  background: linear-gradient(180deg, #0e240b, rgba(0, 0, 0, 1)) padding-box,
    linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 1))
      border-box;

  border: 2px solid transparent;
}

.sub-title-tile {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.title-tile {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
