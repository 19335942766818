<template>
  <section class="wrapper">
    <h3 class="sub-title mb-10 outlined-green">преимущество №1</h3>
    <div class="title-wrapper">
      <div class="title">
        масштабируемые<br />
        приложения для<br />
        малого бизнеса
      </div>
    </div>
    <p class="description mt-15">
      GTS позволяет малому бизнесу создавать<br />
      масштабируемые приложения для любых устройств,<br />
      обслуживающие миллионы пользователей, с<br />
      минимальными затратами и без переписывания системы.
    </p>
    <div class="divider-vertical" style="height: 140px; margin: 120px 0"></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection11",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
  position: relative;
  padding: 30px;
}

.title-wrapper {
  position: relative;
}

.title::before,
.title::after {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 20px 0 20px 0;
}

.title::before {
  top: 0;
  left: 0;
  border-top: 5px solid #2da01a;
  border-left: 5px solid #2da01a;
}

.title::after {
  bottom: 0;
  right: 0;
  border-bottom: 5px solid #2da01a;
  border-right: 5px solid #2da01a;
}

.title-wrapper::before,
.title-wrapper::after {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 0 20px 0 20px;
}

.title-wrapper::before {
  top: 0;
  right: 0;
  border-top: 5px solid #2da01a;
  border-right: 5px solid #2da01a;
}

.title-wrapper::after {
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #2da01a;
  border-left: 5px solid #2da01a;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
