<template>
  <section class="wrapper">
    <img src="@/assets/images/image-block-7.png" alt="background" />
    <div class="content">
      <h2 class="title mb-10">
        Создаем <br />
        технологический <br />
        прорыв с помощью <br />
        коллективного <br />
        заказчика
      </h2>
      <p class="description">
        Объединим ресурсы для создания <br />
        мощного коллективного заказчика <br />
        GTS и достигнем технологического <br />
        прорыва, который <span class="outlined-white green">уравняет</span> ваши
        <br />
        возможности с крупными игроками.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection7",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.content {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.description {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}
</style>
