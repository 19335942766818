<template>
  <section class="wrapper">
    <h2 class="hero-title">
      <span class="outlined-white">1% компаний</span> <br />
      контролируют цифровую <br />
      инфраструктуру, <br />стандарты и извлекают <br />
      прибыль из ваших данных.
    </h2>
    <img class="hero-img" src="@/assets/images/image-block-3.png" alt="img" />
    <h2 class="hero-title second">
      В игре с цифровым <br />
      агрегатором,<br />
      как в казино, <br />
      <span class="outlined-white">гарантированно</span> <br />
      выигрывает только <br />
      владелец цифровой <br />монополии.
    </h2>
    <p class="sub-title">экономика неравенства</p>
    <span class="description mt-5"> 1% получает все </span>
  </section>
</template>

<script>
export default {
  name: "BlockSection3",
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.outlined-white {
  background-color: white;
  color: #000;
  border-radius: 23px;
  padding: 0 20px;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.3457px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 75px;
}

.description {
  font-family: "Adieu Bold", sans-serif;
  font-size: 76px;
  font-weight: 700;
  line-height: 91px;
  letter-spacing: -0.3457px;
  text-align: center;
  text-transform: uppercase;
}
</style>
