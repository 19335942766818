<template>
  <div class="header">
    <div class="wrapper">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/icons/Home-link.svg" alt="Logo" />
        </router-link>
      </div>
      <div class="menu">
        <router-link to="/" class="menu-item mr-2">Главная</router-link>
        <router-link to="/" class="menu-item mr-2">О Проблеме</router-link>
        <router-link to="/" class="menu-item mr-2">О gts</router-link>
        <div class="d-flex align-items-center">
          <router-link to="/" class="btn-link">
            <span class="menu-item">Принять Участие</span>
            <img src="@/assets/icons/icon_go_to.svg" alt="Go to" class="ml-7" />
          </router-link>
          <button class="burger-btn" @click="toggleBurgerMenu">
            <img
              src="@/assets/icons/material-symbols_menu.svg"
              alt="Burger Menu"
            />
          </button>
        </div>
      </div>
    </div>

    <div v-if="isBurgerMenuOpen" class="burger-menu">
      <span class="burger-title">Содержание</span>
      <div class="horizontal-line"></div>
      <router-link to="/" class="burger-menu-item">О проблеме</router-link>
      <router-link to="/" class="burger-menu-item">Проблема 1</router-link>
      <router-link to="/" class="burger-menu-item">Проблема 2</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadMenu",
  data() {
    return {
      isBurgerMenuOpen: false,
    };
  },
  methods: {
    toggleBurgerMenu() {
      this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 14px 18px 14px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.95;
  border-radius: 100px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid;
  border-image-source: linear-gradient(
    113.95deg,
    rgba(210, 210, 210, 0.2) 1.71%,
    rgba(105, 105, 105, 0.2) 99.68%
  );
}

.menu {
  display: flex;
  align-items: center;
  max-width: 45%;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
}

.menu-item {
  text-transform: uppercase;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 30px;
  border: none;
  background-color: #fff;
  color: var(--black);
  text-decoration: none;
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.burger-btn {
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.burger-menu {
  position: absolute;
  top: 100px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid;
  border-image-source: linear-gradient(
    113.95deg,
    rgba(210, 210, 210, 0.2) 1.71%,
    rgba(105, 105, 105, 0.2) 99.68%
  );
  border-radius: 20px;
  padding: 20px 17px;
  min-width: 260px;
}

.burger-menu::before {
  content: "";
  position: absolute;
  top: -20px; /* Отступ сверху */
  right: 32px; /* Отступ от кнопки бургер-меню */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
}

.burger-title {
  font-size: 20px;
  font-weight: 550;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin: 10px 0;
}

.burger-menu-item {
  display: block;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.burger-menu-item:last-child {
  margin-bottom: 0;
}
</style>
