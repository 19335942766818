<template>
  <section class="wrapper">
    <div class="d-flex align-items-center justify-content-between w-75 h-auto">
      <div class="left-side">
        <img
          class="brand-image"
          src="@/assets/icons/brand-icon.png"
          alt="brand"
        />
        <h2 class="title mb-5">
          GTS — комплексное<br />
          преимущество для<br />
          вашего бизнеса.
        </h2>
        <h3 class="sub-title">
          Мы понимаем риски современных<br />
          технологий и создаем решения,<br />
          защищающие ваш бизнес от угроз.
        </h3>
      </div>
      <div class="right-side">
        <div class="tail">
          <img src="@/assets/images/image-block-18.png" alt="tail" />
          <img
            class="icon-tail"
            src="@/assets/icons/icon-go-to-white.png"
            alt="go-to"
          />
          <h3 class="tail-title">О проблемах</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection18",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.left-side {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  margin: 0 30px;
  padding: 35px 10px;
}

.brand-image {
  position: absolute;
  left: -55px;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.tail {
  position: relative;
}

.icon-tail {
  position: absolute;
  top: 15%;
  right: 10%;
}

.tail-title {
  position: absolute;
  bottom: 15%;
  left: 10%;
  font-size: 36px;
  font-weight: 550;
  line-height: 43px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
