<template>
  <section class="wrapper">
    <h3 class="sub-title mb-10">ЦИТАТЫ СИЛЬНЫХ</h3>
    <h2 class="title">
      «Тим Бернерс-Ли создал <br />
      интернет-экономику,<br /><span class="green"
        >а GTS урАвнял всех в <br />
        правах»</span
      >
    </h2>
    <div class="content">
      <div class="content-title">
        <div class="title-label">GTS</div>
        <div class="title-description">
          GREENER<br />
          TRANSACTION<br />
          SERVER
        </div>
      </div>
      <h2 class="content-body">
        система, обеспечивающая запуск и управление <br />
        гетерогенными облачными приложениями с <br />
        высоким уровнем надежности и безопасности,<br />
        оптимизированная для взаимодействия с<br />
        различными терминальными устройствами в<br />
        условиях высоких нагрузок и масштабируемости
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection8",
};
</script>

<style scoped>
.wrapper {
  margin-top: 300px;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  background-image: url("@/assets/images/image-block-8.png");
  background-size: 100% 55%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.content {
  margin-top: 250px;
}

.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title-label {
  font-family: "Adieu Bold", sans-serif;
  font-size: 188px;
  font-weight: 300;
  line-height: 225px;
  letter-spacing: -1.4086px;
  text-align: left;
}

.title-description {
  font-size: 49px;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: -0.223px;
  text-align: left;
}

.content-body {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
