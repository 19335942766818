<template>
  <section class="wrapper">
    <h2 class="hero-title">
      Санкции и высокая <br />
      конкуренция давят <br />
      на малый и средний <br />
      бизнес,
    </h2>
    <h2 class="sub-title my-15">
      в то время как крупные компании <br />
      укрепляют позиции за счет <br />
      инвестиций в инфраструктуру и <br />
      поглащение прибыли менее удачливых <br />
      участников рынка, перекладывая на <br />
      них часть своих издержек
    </h2>
    <h2 class="hero-title my-15">
      компании вынуждены <br />
      платить за призрачную <br />
      IT-безопасность.<br />Только в России потери<br />превысили
      <span class="outlined-white red">$10 млрд.</span>
    </h2>
    <div class="graphic my-15">
      <img src="@/assets/images/image-block-5.png" alt="Graphic" />
      <span class="graphic-title">
        Потери малого и среднего бизнеса <br />
        в IT сфере и безопасности в РФ
      </span>
      <a href="#" class="source-link"
        >Источник: <span class="underline">rbc.ru</span></a
      >
    </div>
    <h2 class="hero-title my-15">
      Отключение от мировых <br />
      сервисов лишает <br />
      конкурентоспособности <br />
      отечественный бизнес
    </h2>
  </section>
</template>

<script>
export default {
  name: "BlockSection5",
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.red {
  color: #fc3f1d;
}

.graphic {
  position: relative;
}

.graphic-title {
  position: absolute;
  left: 25px;
  top: 55px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3574px;
  text-align: left;
}

.source-link {
  position: absolute;
  right: 20px;
  bottom: 15px;
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
}
</style>
