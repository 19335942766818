<template>
  <section class="wrapper">
    <img class="image" src="@/assets/images/image-block-12.png" alt="img" />
    <h3 class="sub-title mt-15 outlined-green">преимущество №2</h3>
    <h2 class="title my-15">
      быстрое создание и<br />
      адаптация продуктов
    </h2>
    <p class="content">
      Среда разработки GTS позволяет собирать<br />
      решения любой сложности
      <span class="outlined-white green">как из кубиков Lego,</span><br />
      ускоряя создание продуктов и адаптацию <br />
      архитектур под новые рынки.
    </p>
    <div
      class="divider-vertical"
      style="height: 140px; margin-top: 120px"
    ></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection12",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.content {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
