<template>
  <section class="wrapper">
    <div class="row d-flex flex-row align-items-center justify-content-center">
      <div class="card-wrapper" style="width: 35%">
        <div
          class="card"
          ref="card"
          style="
            width: 100%;
            background-image: url('/images/card-background-1.png');
          "
        >
          <span class="card-title">ПРОБЛЕМА №1</span>
          <div class="card-description">
            Медленная <br />
            адаптация
          </div>
        </div>
      </div>
      <div class="card-wrapper" style="width: 27%">
        <div
          class="card"
          style="background-image: url('/images/card-background-2.png')"
        >
          <span class="card-title">ПРОБЛЕМА №2</span>
          <div class="card-description">
            Рост <br />
            расходов
          </div>
        </div>
      </div>
      <div class="card-wrapper" style="width: 32%">
        <div
          class="card"
          style="background-image: url('/images/card-background-3.png')"
        >
          <span class="card-title">ПРОБЛЕМА №3</span>
          <div class="card-description">
            Уязвимость <br />
            систем
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex flex-row align-items-center justify-content-center">
      <div class="card-wrapper" style="width: 30%">
        <div
          class="card"
          style="background-image: url('/images/card-background-4.png')"
        >
          <span class="card-title">ПРОБЛЕМА №4</span>
          <div class="card-description">
            Непрерывность <br />
            инфраструктуры
          </div>
        </div>
      </div>
      <div class="card-wrapper" style="width: 30%">
        <div
          class="card"
          style="background-image: url('/images/card-background-5.png')"
        >
          <span class="card-title">ПРОБЛЕМА №5</span>
          <div class="card-description">
            Монополизация <br />
            платформ
          </div>
        </div>
      </div>
      <div class="card-wrapper" style="width: 34%">
        <div
          class="card"
          style="background-image: url('/images/card-background-6.png')"
        >
          <span class="card-title">ПРОБЛЕМА №6</span>
          <div class="card-description">
            Игнорирование <br />
            бизнеса
          </div>
        </div>
      </div>
    </div>
    <div class="divider-vertical my-15" style="height: 108px"></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection16",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.row {
  max-width: 70%;
  width: 100%;
}

.card-wrapper {
  height: 370px;
  overflow: hidden;
  margin: 12px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 2px solid #fff;
  padding: 25px;
  border-radius: 30px;
  background-size: cover;
  height: 370px;
  width: 100%;
}

.card-title {
  border: 1px solid #ffffff;
  padding: 8px 20px;
  border-radius: 24px;
}

.card-description {
  font-size: 36px;
  font-weight: 550;
  line-height: 43px;
  text-align: left;
  text-transform: uppercase;
}
</style>
