<template>
  <section class="wrapper">
    <div class="content-block">
      <h2 class="title mb-10">
        <span class="green">GTS</span> – когда перемены<br />
        превращаются в<br />
        вашу прибыль.
      </h2>
      <h2 class="title">
        Преуспей в эпоху<br />
        перемен.
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection9",
};
</script>

<style scoped>
.wrapper {
  padding: 250px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  background-image: url("@/assets/images/image-block-9.png");
  background-size: contain;
  background-position: center calc(100% - 30px);
  background-repeat: no-repeat;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
