<template>
  <section class="wrapper">
    <img class="hero-img" src="@/assets/images/image-block-2.png" alt="img" />
    <div class="hero">
      <h2 class="hero-title">
        Создай новое <br />
        поле игры!
      </h2>
      <p class="description">
        Вступайте в консорциум Онтосеть, <br />
        чтобы создать стратегическое <br />
        преимущество и гарантировать <br />
        рентабельность вашего бизнеса завтра!
      </p>
    </div>
    <div class="divider-horizontal"></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection2",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: 250px;
}

.hero {
  position: absolute;
  top: 50%;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}

.description {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
}
</style>
