<template>
  <section class="wrapper">
    <h2 class="hero-title text-start mb-10">
      <span class="red">«99% трафика</span> в Интернете <br />
      приходится на меньше <br />
      чем <span class="red"> 1% сайтов,</span>
    </h2>
    <h2 class="hero-title text-end">
      <span class="red"> 99% книжных продаж</span><br />– на
      <span class="red">1% авторов»</span>
    </h2>
    <div class="blockquote-author">
      <img class="mr-3" src="@/assets/images/nasim-talleb.png" alt="img" />
      <div>
        <h4 class="name">Нассим Таллеб,</h4>
        <h4 class="name">«Антихрупкость»</h4>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection4",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: 250px;
  background-image: url("@/assets/images/image-block-4.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding: 300px 0;
}

.red {
  color: #fc3f1d;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-transform: uppercase;
  max-width: 1400px;
  width: 100%;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.blockquote-author {
  max-width: 1400px;
  width: 100%;
  display: flex;
  align-items: center;
}

.name {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}
</style>
