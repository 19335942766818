<template>
  <div class="home">
    <BlockSection1 />
    <BlockSection2 />
    <BlockSection3 />
    <BlockSection4 />
    <div class="divider-horizontal"></div>
    <BlockSection5 />
    <BlockSection6 />
    <div class="divider-horizontal"></div>
    <BlockSection7 />
    <BlockSection8 />
    <BlockSection9 />
    <div class="divider-horizontal mt-0"></div>
    <BlockSection10 />
    <BlockSection11 />
    <BlockSection12 />
    <BlockSection13 />
    <BlockSection14 />
    <div class="divider-horizontal mt-0"></div>
    <BlockSection15 />
    <BlockSection16 />
    <BlockSection17 />
    <div class="divider-horizontal"></div>
    <BlockSection18 />
    <div class="divider-horizontal"></div>
  </div>
</template>

<script>
import BlockSection1 from "@/components/HomeView/BlockSection1.vue";
import BlockSection2 from "@/components/HomeView/BlockSection2.vue";
import BlockSection3 from "@/components/HomeView/BlockSection3.vue";
import BlockSection4 from "@/components/HomeView/BlockSection4.vue";
import BlockSection5 from "@/components/HomeView/BlockSection5.vue";
import BlockSection6 from "@/components/HomeView/BlockSection6.vue";
import BlockSection7 from "@/components/HomeView/BlockSection7.vue";
import BlockSection8 from "@/components/HomeView/BlockSection8.vue";
import BlockSection9 from "@/components/HomeView/BlockSection9.vue";
import BlockSection10 from "@/components/HomeView/BlockSection10.vue";
import BlockSection11 from "@/components/HomeView/BlockSection11.vue";
import BlockSection12 from "@/components/HomeView/BlockSection12.vue";
import BlockSection13 from "@/components/HomeView/BlockSection13.vue";
import BlockSection14 from "@/components/HomeView/BlockSection14.vue";
import BlockSection15 from "@/components/HomeView/BlockSection15.vue";
import BlockSection16 from "@/components/HomeView/BlockSection16.vue";
import BlockSection17 from "@/components/HomeView/BlockSection17.vue";
import BlockSection18 from "@/components/HomeView/BlockSection18.vue";

export default {
  name: "HomeView",
  components: {
    BlockSection6,
    BlockSection5,
    BlockSection4,
    BlockSection1,
    BlockSection2,
    BlockSection3,
    BlockSection7,
    BlockSection8,
    BlockSection9,
    BlockSection10,
    BlockSection11,
    BlockSection12,
    BlockSection13,
    BlockSection14,
    BlockSection15,
    BlockSection16,
    BlockSection17,
    BlockSection18,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.home {
  margin-top: 120px;
  width: 100%;
  height: 100%;
}
</style>
