<template>
  <section class="wrapper">
    <img src="@/assets/images/image-block-13.png" alt="" />
    <h3 class="sub-title mt-15 outlined-green">преимущество №3</h3>
    <h2 class="title my-15">
      Единое<br />
      пространство <br />
      приложений<br />
      на базе GTS
    </h2>
    <p class="content">
      На базе GTS будут созданы интегрированные приложения,<br />
      обеспечивающие бесшовное взаимодействие и простое<br />
      подключение устройств, например, чайника, к<br />
      банковскому счету вашей компании. Идеальное решение<br />
      для сервисной экономики
    </p>
    <div
      class="divider-vertical"
      style="height: 140px; margin-top: 120px"
    ></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection13",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sub-title {
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.content {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}
</style>
