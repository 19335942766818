<template>
  <section class="wrapper">
    <div class="tail d-flex align-items-center justify-content-around">
      <div class="side left">ПРОБЛЕМ</div>
      <div class="divider-horizontal" style="max-width: 100px"></div>
      <div class="middle">7</div>
      <div class="divider-horizontal pr-5" style="max-width: 100px"></div>
      <div class="side right">РЕШЕНИЙ</div>
    </div>
    <h4 class="sub-title">
      Современные цифровые инструменты и стандарты<br />
      <span class="outlined-white red">не выведут вас из</span>
      ловушки текущих IT-технологий.
    </h4>
    <h3 class="description mt-15">
      НО ЕСТЬ РЕШЕНИЕ:<br />
      БАЗОВЫЙ СТЕК GTS - ОСНОВА КИБЕРНЕТИЧЕСКОЙ<br />
      ЭКОНОМИКИ БУДУЩЕГО
    </h3>
    <div class="divider-vertical mt-0 mb-15" style="height: 108px"></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection15",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.tail {
  max-width: 70%;
  width: 100%;
}

.side {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.left {
  background-image: url("@/assets/images/image-block-15-left.png");
  background-size: 100% 80%;
  background-position: 50px;
  background-repeat: no-repeat;
  padding: 250px 70px;
}

.right {
  background-image: url("@/assets/images/image-block-15-right.png");
  background-size: 100% 80%;
  background-position: -50px;
  background-repeat: no-repeat;
  padding: 250px 70px;
}

.middle {
  font-family: "Adieu Bold", sans-serif;
  font-size: 200px;
  font-weight: 700;
  line-height: 180px;
  letter-spacing: -1.3788px;
  text-align: center;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.description {
  font-size: 32px;
  font-weight: 550;
  line-height: 38px;
  letter-spacing: -0.086px;
  text-align: center;
  padding: 6px 20px;
  border: 1px solid #ffffff33;
  border-radius: 24px;
}
</style>
