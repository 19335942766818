<template>
  <section class="wrapper">
    <h3 class="sub-title mb-10">ДЛЯ ЧЕГО НУЖЕН GTS?</h3>
    <h2 class="title">
      <span class="green">GTS</span> – источник<br />
      долгосрочных<br />
      конкурентных<br />
      преимуществ
    </h2>
    <div class="divider-vertical" style="height: 140px; margin: 120px 0"></div>
  </section>
</template>

<script>
export default {
  name: "BlockSection10",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  text-align: center;
}
</style>
