<template>
  <section class="wrapper">
    <div class="wrapper-content">
      <div
        class="col d-flex flex-column align-items-start justify-content-between"
      >
        <h4 class="sub-title">ЗАПРЕТ СО СТОРОНЫ США</h4>
        <h3 class="title my-10">
          США ЗАПРЕТИЛИ <br />
          <img
            src="@/assets/icons/material-symbols-light_lock-outline.svg"
            alt=""
          />РОССИИ РЯД <br />
          УСЛУГ В СФЕРЕ it
        </h3>
        <h5 class="description">
          Минфин США в сотрудничестве <br />
          с Госдепом выпустил новое <br />
          определение (E.O. 14071), <br />
          запрещающее услуги IT- <br />поддержки и облачные <br />
          сервисы для ПО и управления <br />
          предприятием. <br />
          <p class="pt-5">
            вступление в силу<br /><span class="red"
              >12 сентября 2024 года.</span
            >
          </p>
        </h5>
      </div>
      <div
        class="col d-flex flex-column align-items-center justify-content-end"
      >
        <img src="@/assets/images/image-block-6.png" alt="section6" />
        <a href="#" class="source-link"
          >Источник: <span class="underline">rbc.ru</span></a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlockSection6",
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.wrapper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.description {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.source-link {
  position: absolute;
  bottom: 17%;
  left: 21%;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  opacity: 0.5;
}

.col {
  position: relative;
  width: 50%;
}
.red {
  color: #fc3f1d;
}
</style>
