<template>
  <mainWrapper>
    <div ref="mainContainer" class="main-container">
      <router-view />
    </div>
  </mainWrapper>
</template>

<script>
import MainWrapper from "@/components/MainWrapper.vue";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "App",
  components: {
    MainWrapper,
  },
  data() {
    return {
      locoScroll: null,
    };
  },
  mounted() {
    this.locoScroll = new LocomotiveScroll({
      el: this.$refs.mainContainer,
      smooth: true, // Плавная прокрутка
      lerp: 0.11, // Коэффициент сглаживания
      getSpeed: true,
      getDirection: true,
      scrollbar: true, // Включаем нативный скроллбар
    });

    ScrollTrigger.scrollerProxy(this.$refs.mainContainer, {
      scrollTop: (value) => {
        return arguments.length
          ? this.locoScroll.scrollTo(value, 0, 0)
          : this.locoScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect: () => {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: this.$refs.mainContainer.style.transform ? "transform" : "fixed",
    });

    this.locoScroll.on("scroll", ScrollTrigger.update);
    ScrollTrigger.refresh();

    window.addEventListener("load", () => {
      this.locoScroll.update();
    });

    window.locoScroll = this.locoScroll;
  },
  beforeUnmount() {
    if (this.locoScroll) {
      this.locoScroll.destroy();
    }
  },
};
</script>

<style>
.main-container {
  overflow-y: hidden;
}
</style>
